export const Parcerias = (props) => {
  return (
    <div id='parcerias' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Parceria de tecnologia</h2>
          <p>
          Nossa consultoria executa projetos personalizados e possuímos parceiros de tecnologia e negócio que contribuem para a criação e desenvolvimento de soluções efetivas dentro da necessidade de cada um de nossos clientes, com soluções flexíveis para qualquer perfil, segmento ou tamanho de negócio.
          </p>
        </div>
        <div id='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-3 col-sm-8 team'>
                  <div className='thumbnail'>
                    {' '}
                    <img src={d.img} alt='...' className='team-img' />
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
